const fallbackLng = 'en'
// const languages = [fallbackLng, 'ru', 'de', 'pt', 'ja', 'tr'] // Languages are disabled for now, because translations are not ready
const languages = [fallbackLng]
const defaultNS = 'common'

export const getI18nSettings = (lng = fallbackLng, ns: string | string[] = defaultNS) => ({
  debug: process.env.IS_LOCAL === 'true',
  supportedLngs: languages,
  fallbackLng,
  lng,
  fallbackNS: defaultNS,
  defaultNS,
  ns,
  react: { useSuspense: false }
})
