import type { NormalizedRace } from 'src/store/types'
import type { RaceDTO } from 'src/services/supabase/queries'
import type { RaceRowDTO } from 'src/types/infra/supabase/RaceRowDTO'
import type { Race } from '../../domain/Race'
import { RaceEventMap } from '../RaceEventMap'

export const fromRaceDTO = (race: RaceDTO): Race => {
  return {
    uuid: race.id,
    round: race.round,
    createdAt: race.created_at,
    raceConfigurationId: race.race_configuration_id,
    raceEvents: race.race_event.map(RaceEventMap.fromRaceEventRowDTO)
  }
}

export const fromRaceRowDTO = (raceRow: RaceRowDTO): Omit<Race, 'raceEvents'> => {
  return {
    uuid: raceRow.id,
    round: raceRow.round,
    createdAt: raceRow.created_at,
    raceConfigurationId: raceRow.race_configuration_id
  }
}

export const toNormalizedRace = (race: Race): NormalizedRace => {
  return {
    id: race.uuid,
    createdAt: race.createdAt,
    round: race.round,
    raceConfigurationId: race.raceConfigurationId,
    raceEventIds: race.raceEvents.map((event) => event.uuid)
  }
}
