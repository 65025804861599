import { isNonEmptyArray, type NonEmptyArray } from 'effect/Array'
import { Array } from 'effect'
import type { GameDTO, RaceConfigurationDTO } from 'src/services/supabase/queries'
import type { NormalizedMarbleGame } from 'src/store/types'
import type { Race } from 'src/types/domain/Race'
import { unknownGame, type Game, type MarbleGame } from 'src/types/domain/Game'
import type { Tables } from 'src/types/infra/supabase/database.types'
import { MarbleMap } from '../MarbleMap'
import { TrackMap } from '../TrackMap'
import { RaceMap } from '../RaceMap'

export const fromGameDTO = (game: GameDTO, races: NonEmptyArray<Race>): Game => {
  return {
    type: 'MarbleGame',
    id: game.id,
    active: game.active,
    name: game.name,
    laps: game.laps,
    createdAt: game.created_at,
    maxPickableMarbles: game.max_pickable_marbles,
    totalMarbles: game.total_marbles,
    track: TrackMap.fromTrackDTO(game.track),
    marbles: game.marble.map(MarbleMap.fromMarbleDTO),
    races
  }
}

export const fromGameRowDTO = (
  gameRow: Tables<'race_configuration'>
): Omit<MarbleGame, 'type' | 'marbles' | 'track' | 'races'> => {
  return {
    id: gameRow.id,
    name: gameRow.name,
    laps: gameRow.laps,
    maxPickableMarbles: gameRow.max_pickable_marbles,
    totalMarbles: gameRow.total_marbles,
    active: gameRow.active,
    createdAt: gameRow.created_at,
    previewThumbnail: gameRow.preview_thumbnail ?? undefined
  }
}

export const fromRaceConfigurationDTO = (dto: RaceConfigurationDTO): Game => {
  if (!isNonEmptyArray(dto.race)) {
    return unknownGame
  }

  return {
    type: 'MarbleGame',
    id: dto.id,
    active: dto.active,
    name: dto.name,
    createdAt: dto.created_at,
    marbles: dto.marble.map(MarbleMap.fromMarbleDTO),
    track: TrackMap.fromTrackDTO(dto.track),
    races: Array.map(dto.race, RaceMap.fromRaceDTO),
    laps: dto.laps,
    maxPickableMarbles: dto.max_pickable_marbles,
    totalMarbles: dto.total_marbles,
    // broadcast: dto.br,
    previewThumbnail: dto.preview_thumbnail ?? undefined
  }
}

export const toNormalizedMarbleGame = (game: MarbleGame): NormalizedMarbleGame => {
  return {
    id: game.id,
    name: game.name,
    laps: game.laps,
    maxPickableMarbles: game.maxPickableMarbles,
    totalMarbles: game.totalMarbles,
    active: game.active,
    trackId: game.track.uuid,
    createdAt: game.createdAt,
    previewThumbnail: game.previewThumbnail,
    type: 'MarbleGame',
    marbleIds: game.marbles.map((marble) => marble.uuid),
    raceIds: Array.map(game.races, (race) => race.uuid)
  }
}
