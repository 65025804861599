import { Order } from 'effect'
import type { NonEmptyArray } from 'effect/Array'
import type { RaceSettledEvent } from 'src/types/domain/RaceEvent'

export interface NormalizedMarbleGame {
  type: 'MarbleGame'
  marbleIds: string[]
  id: string
  trackId: string
  name: string
  laps: number
  totalMarbles: number
  maxPickableMarbles: number
  active: boolean
  previewThumbnail?: string
  broadcastIds?: string[]
  raceIds: NonEmptyArray<string>
  createdAt: string
}

export interface NormalizedRace {
  id: string
  round: number
  createdAt: string
  raceConfigurationId: string
  raceEventIds: string[]
}

export const orderRacesByCreatedAt = Order.mapInput(
  Order.reverse(Order.string),
  (race: NormalizedRace) => race.createdAt
)

export interface NormalizedRaceEvent {
  uuid: string
  raceId: string
  eventType: string
  eventTimestamp: string
}

export interface NormalizedRaceSettledEvent extends NormalizedRaceEvent {
  eventType: 'race_settled'
  payload: {
    results: {
      marbleId: string
      marbleNumber: number
      marbleColor: string
      position: number
    }[]
  }
}

export const isNormalizedRaceSettledEvent = (
  raceEvent: NormalizedRaceEvent
): raceEvent is NormalizedRaceSettledEvent => raceEvent.eventType === 'race_settled'

export const orderRaceEventsByTimestamp = Order.mapInput(
  Order.string,
  (raceEvent: NormalizedRaceEvent) => raceEvent.eventTimestamp
)

export const orderResultsByPosition = Order.mapInput(
  Order.number,
  (result: RaceSettledEvent['payload']['results'][number]) => result.position
)

export interface NormalizedMarble {
  uuid: string
  color: string
  weight: string
  picture: string | null
  image: string | null
  imageAlt: string | null
  rfidId: string | null
  number: string
  active: boolean
  characteristics: string | null
  raceConfigurationId: string | null
  createdAt: string
}

export interface NormalizedTrack {
  uuid: string
  active: boolean
  showInLobby: boolean
  createdAt: string
  description: string | null
  image: string
  poster: string
  background: string
  name: string
  shortName: string
  modeId: string
}

export interface NormalizedTrackMode {
  uuid: string
  name: string
  type: string
  trackId: string
  active: boolean
}
