export interface Balance {
  amount: number
  currency: string
}

export interface UnknownPlayer {
  type: 'UnknownPlayer'
}

export interface AnonymousPlayer {
  type: 'AnonymousPlayer'
}

export interface AuthenticatedPlayer {
  type: 'AuthenticatedPlayer'
  token: string
  id: string
  sessionId: string
  username: string
  avatar: string
  // created_at: string
  balance: Balance
  lang?: string // TODO: Remove optional
  country?: string // TODO: Remove optional
}

export type Player = UnknownPlayer | AnonymousPlayer | AuthenticatedPlayer

export const unknownPlayer: UnknownPlayer = {
  type: 'UnknownPlayer'
}

export const anonymousPlayer: AnonymousPlayer = {
  type: 'AnonymousPlayer'
}

export const isUnknownPlayer = (player: Player): player is UnknownPlayer =>
  player.type === 'UnknownPlayer'

export const isAnonymousPlayer = (player: Player): player is AnonymousPlayer =>
  player.type === 'AnonymousPlayer'

export const isAuthenticatedPlayer = (player: Player): player is AuthenticatedPlayer =>
  player.type === 'AuthenticatedPlayer'
