'use client'
/**
 * In order to successfully ssr the client components the useClientSideTranslation function
 * need to be aware of the server language.
 */
import i18next from 'i18next'
import HttpBackend from 'i18next-http-backend'
import { initReactI18next, useTranslation as useTranslationNext } from 'react-i18next'
import { getI18nSettings } from './utils/getI18nSettings'

const { supportedLngs, defaultNS } = getI18nSettings()

const runsOnServerSide = typeof window === 'undefined'

void i18next
  .use(initReactI18next)
  .use(HttpBackend)
  .init({
    ...getI18nSettings(),
    lng: undefined, // let detect the language on client side
    preload: runsOnServerSide ? supportedLngs : [],
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    }
  })

/**
 * This wrapper exists so you can provide a language when a client component is in SSR mode for the initial render.
 * Not providing a language will cause hydration errors when using the app in other languages than 'en'.
 */
export const useClientSideTranslation = (lng: string, ns: string = defaultNS, options?: any) => {
  const ret = useTranslationNext(ns, options)
  const { i18n } = ret

  if (runsOnServerSide && lng && i18n.resolvedLanguage !== lng) {
    void i18n.changeLanguage(lng)
  } else if (!runsOnServerSide && lng && i18n.language !== lng) {
    void i18n.changeLanguage(lng)
  }

  return {
    ...ret,
    t: (key?: string, options?: any) => {
      const translation = key ? ret.t(key, options) : ''
      return typeof translation === 'string' ? translation : String(translation)
    }
  }
}
