import { z } from 'zod'

export interface RaceEvent {
  uuid: string
  raceId: string
  eventType: string
  eventTimestamp: string
}

export interface RaceSettledEvent extends RaceEvent {
  eventType: 'race_settled'
  payload: {
    results: {
      marbleId: string
      marbleNumber: number
      marbleColor: string
      position: number
    }[]
  }
}

export interface RaceCancelledEvent extends RaceEvent {
  eventType: 'race_cancelled'
}

const raceEventSchema = z.object({
  uuid: z.string(),
  raceId: z.string(),
  eventType: z.string(),
  eventTimestamp: z.string()
})

const raceSettledEventSchema = raceEventSchema.extend({
  eventType: z.literal('race_settled'),
  payload: z.object({
    results: z.array(
      z.object({
        marbleId: z.string(),
        marbleNumber: z.number(),
        marbleColor: z.string(),
        position: z.number()
      })
    )
  })
})

const raceCancelledEventSchema = raceEventSchema.extend({
  eventType: z.literal('race_cancelled')
})

export const isRaceSettledEvent = (event: RaceEvent): event is RaceSettledEvent => {
  return raceSettledEventSchema.safeParse(event).success
}

export const isRaceCancelledEvent = (event: RaceEvent): event is RaceCancelledEvent => {
  return raceCancelledEventSchema.safeParse(event).success
}
