import type { NormalizedMarble } from 'src/store/types'
import type { Marble } from '../../domain/Marble'
import type { Tables } from '../../infra/supabase/database.types'

export const fromMarbleDTO = (marble: Tables<'marble'>): Marble => {
  return {
    uuid: marble.id,
    number: marble.number,
    active: marble.active,
    color: marble.color,
    characteristics: marble.marble_characteristics,
    picture: marble.picture,
    image: marble.image,
    imageAlt: marble.image_alt,
    weight: marble.weight,
    rfidId: marble.rfid_id,
    raceConfigurationId: marble.race_configuration_id,
    createdAt: marble.created_at
  }
}

export const fromNormalizedMarble = (marble: NormalizedMarble): Marble => {
  return {
    ...marble
  }
}

export const toNormalizedMarble = (marble: Marble): NormalizedMarble => {
  return {
    ...marble
  }
}
