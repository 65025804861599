import type { FlatNamespace, KeyPrefix, TFunction } from 'i18next'
import i18n from 'i18next'
import HttpBackend from 'i18next-http-backend'
import type { FallbackNs } from 'react-i18next'
import { initReactI18next } from 'react-i18next'
import { getI18nSettings } from './utils/getI18nSettings'

const { defaultNS } = getI18nSettings()

const initI18next = async (lng: string, ns: string | string[]) => {
  const i18nInstance = i18n.createInstance()

  await i18nInstance
    .use(initReactI18next)
    .use(HttpBackend)
    .init({
      ...getI18nSettings(lng, ns),
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json'
      }
    })

  return i18nInstance
}

export const useTranslation = async <
  Ns extends FlatNamespace,
  KPrefix extends KeyPrefix<FallbackNs<Ns>> = undefined
>(
  lng: string,
  ns: string = defaultNS,
  options: { keyPrefix?: KPrefix } = {}
): Promise<{ t: TFunction; i18n: typeof i18n }> => {
  const i18nextInstance = await initI18next(lng, ns)

  return {
    t: i18nextInstance.getFixedT(lng, Array.isArray(ns) ? ns[0] : ns, options.keyPrefix),
    i18n: i18nextInstance
  }
}
