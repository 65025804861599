import { z } from 'zod'

// TODO: rename KnownGameConfig and UnknownGameConfig
// Currently "Game" is a bit confusing because it is used to talk about the Widget but also the RaceConfiguration
export interface KnownGameConfig {
  externalLobbyUrl?: string
  externalDepositUrl?: string
  platform: 'MOBILE' | 'DESKTOP'
  language?: string
  country?: string
  bet: {
    currency: string
    limits: {
      PickWinner: {
        min: number
        max: number
      }
      ReverseForecast: {
        min: number
        max: number
      }
      StraightForecast: {
        min: number
        max: number
      }
      CombinationTricast: {
        min: number
        max: number
      }
      Tricast: {
        min: number
        max: number
      }
    }
  }
}

export type UnknownGameConfig = Partial<KnownGameConfig>

const BetLimits = z.object({
  min: z.number(),
  max: z.number()
})

const KnownGameConfigSchema = z.object({
  externalLobbyUrl: z.string().optional(),
  externalDepositUrl: z.string().optional(),
  platform: z.enum(['MOBILE', 'DESKTOP']),
  language: z.string().optional(),
  country: z.string().optional(),
  bet: z.object({
    currency: z.string(),
    limits: z.object({
      PickWinner: BetLimits,
      ReverseForecast: BetLimits,
      StraightForecast: BetLimits,
      CombinationTricast: BetLimits,
      Tricast: BetLimits
    })
  })
})

export type GameConfig = UnknownGameConfig | KnownGameConfig

export const unknownGameConfig: UnknownGameConfig = {}

export const isKnownGameConfig = (gameConfig: GameConfig): gameConfig is KnownGameConfig =>
  KnownGameConfigSchema.safeParse(gameConfig).success
