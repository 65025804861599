import {
  isNormalizedRaceSettledEvent,
  type NormalizedRaceEvent,
  type NormalizedRaceSettledEvent
} from 'src/store/types'
import { isRaceSettledEvent, type RaceEvent, type RaceSettledEvent } from '../../domain/RaceEvent'
import type { Tables } from '../../infra/supabase/database.types'

export const fromRaceEventRowDTO = (row: Tables<'race_event'>): RaceEvent => {
  if (row.event_type === 'race_settled') {
    const raceSettledEvent: RaceSettledEvent = {
      uuid: row.id,
      raceId: row.race_id,
      eventType: 'race_settled',
      eventTimestamp: row.event_timestamp,
      payload: row.payload as RaceSettledEvent['payload']
    }

    return raceSettledEvent
  }

  return {
    uuid: row.id,
    raceId: row.race_id,
    eventType: row.event_type,
    eventTimestamp: row.event_timestamp
  }
}

export const fromNormalizedRaceEvent = (normalizedRaceEvents: NormalizedRaceEvent): RaceEvent => {
  if (isNormalizedRaceSettledEvent(normalizedRaceEvents)) {
    const raceSettledEvent: RaceSettledEvent = {
      uuid: normalizedRaceEvents.uuid,
      raceId: normalizedRaceEvents.raceId,
      eventType: normalizedRaceEvents.eventType,
      eventTimestamp: normalizedRaceEvents.eventTimestamp,
      payload: normalizedRaceEvents.payload
    }

    return raceSettledEvent
  }
  return {
    uuid: normalizedRaceEvents.uuid,
    raceId: normalizedRaceEvents.raceId,
    eventType: normalizedRaceEvents.eventType,
    eventTimestamp: normalizedRaceEvents.eventTimestamp
  }
}

export const toNormalizedRaceEvent = (raceEvent: RaceEvent): NormalizedRaceEvent => {
  if (isRaceSettledEvent(raceEvent)) {
    const normalizedRaceSettledEvent: NormalizedRaceSettledEvent = {
      uuid: raceEvent.uuid,
      raceId: raceEvent.raceId,
      eventType: raceEvent.eventType,
      eventTimestamp: raceEvent.eventTimestamp,
      payload: raceEvent.payload
    }

    return normalizedRaceSettledEvent
  }

  return {
    uuid: raceEvent.uuid,
    raceId: raceEvent.raceId,
    eventType: raceEvent.eventType,
    eventTimestamp: raceEvent.eventTimestamp
  }
}
