import { create } from 'zustand'
import type { NonEmptyArray } from 'effect/Array'
import { Array as A, Option as O, pipe } from 'effect'
import { original, setAutoFreeze } from 'immer'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { createSelector } from 'reselect'
import type { Game, MarbleGame } from 'src/types/domain/Game'
import { isMarbleGame, isUnknownGame, unknownGame } from 'src/types/domain/Game'
import type { Race } from 'src/types/domain/Race'
import type { RealtimeTrackDTO, RealtimeTrackModeDTO } from 'src/services/supabase/queries'
import { TrackMap } from 'src/types/mappers/TrackMap'
import { TrackModeMap } from 'src/types/mappers/TrackModeMap'
import { GameMap } from 'src/types/mappers/GameMap'
import { MarbleMap } from 'src/types/mappers/MarbleMap'
import { RaceEventMap } from 'src/types/mappers/RaceEventMap'
import { RaceMap } from 'src/types/mappers/RaceMap'
import type { RaceEvent } from 'src/types/domain/RaceEvent'
import type { GameConfigDTO, TokenGameConfigDTO } from 'src/actions/types'
import { getQueryParam } from 'src/utils/qetQueryParam'
import { getI18nSettings } from 'src/services/i18n'
import { isKnownGameConfig, type GameConfig } from 'src/types/domain/GameConfig'
import type { Player } from 'src/types/domain/Player'
import { isAuthenticatedPlayer } from 'src/types/domain/Player'
import {
  type NormalizedMarbleGame,
  type NormalizedMarble,
  type NormalizedRace,
  type NormalizedRaceEvent,
  type NormalizedTrack,
  type NormalizedTrackMode,
  orderRacesByCreatedAt,
  orderRaceEventsByTimestamp
} from './types'
import { isDevtoolsEnabled } from './utils/isDevtoolsEnabled'

setAutoFreeze(false)

export interface GameState {
  currentGameId: string | undefined
  currentLanguage: string
  config: GameConfig
  games: {
    byId: Record<string, NormalizedMarbleGame>
    allIds: string[]
  }
  marbles: {
    byId: Record<string, NormalizedMarble>
    allIds: string[]
  }
  races: {
    byId: Record<string, NormalizedRace>
    allIds: string[]
  }
  raceEvents: {
    byId: Record<string, NormalizedRaceEvent>
    allIds: string[]
  }
  tracks: {
    byId: Record<string, NormalizedTrack>
    allIds: string[]
  }
  trackModes: {
    byId: Record<string, NormalizedTrackMode>
    allIds: string[]
  }
}

export interface GameActions {
  gameUpdated: (game: Partial<NormalizedMarbleGame> & { id: string; type: string }) => void
  raceAdded: (race: NormalizedRace) => void
  raceUpdated: (race: Partial<NormalizedRace> & { id: string }) => void
  raceEventAdded: (raceEvent: RaceEvent) => void
  raceEventUpdated: (raceEvent: RaceEvent) => void
  tokenGameConfigRetrieved: (tokenGameConfig: TokenGameConfigDTO) => void
  updateGameById: (game: Partial<MarbleGame> & { id: string }) => void
  updateTrack: (payload: RealtimeTrackDTO) => void
  updateTrackMode: (payload: RealtimeTrackModeDTO) => void
  setRaces: (gameId: string, races: NonEmptyArray<Race>) => void
  setCurrentGame: (game: Game) => void
  setGames: (games: Record<string, MarbleGame>) => void
  setCurrentLanguage: (language: string) => void
  setGameConfig: (gameConfig: GameConfigDTO) => void
}

type GameStore = GameState & { actions: GameActions }

const { fallbackLng } = getI18nSettings()
const addRaceEventToExistingRace = (state: GameState, raceEvent: NormalizedRaceEvent) => {
  const race = state.races.byId[raceEvent.raceId]

  if (!race) {
    return
  }

  const sortedRaceEventIds = pipe(
    race.raceEventIds,
    A.map((id) => state.raceEvents.byId[id]),
    A.map(O.fromNullable),
    A.getSomes,
    A.append(raceEvent),
    A.sortBy(orderRaceEventsByTimestamp),
    A.map((re) => re.uuid)
  )

  race.raceEventIds = sortedRaceEventIds
}

const addRaceToExistingGame = (state: GameState, race: NormalizedRace) => {
  const game = state.games.byId[race.raceConfigurationId]

  if (!game) {
    return
  }

  const sortedRaceIds = pipe(
    game.raceIds,
    A.map((id) => state.races.byId[id]),
    A.map(O.fromNullable),
    A.getSomes,
    A.append(race),
    A.sortBy(orderRacesByCreatedAt),
    A.map((r) => r.id)
  )

  game.raceIds = sortedRaceIds
}

const removeRaceFromGame = ({
  state,
  previousRace,
  nextRace
}: {
  state: GameState
  previousRace?: NormalizedRace
  nextRace: NormalizedRace
}): void => {
  if (!previousRace) {
    return
  }

  if (previousRace.id !== nextRace.id) {
    console.error('Should not happen, prev and next race are different:', {
      previousRace,
      nextRace
    })
    return
  }

  const game = state.games.byId[previousRace.raceConfigurationId]

  if (!game) {
    return
  }

  if (previousRace.raceConfigurationId !== nextRace.raceConfigurationId) {
    // remove race result from old race
    // ? How to fix this ?
    game.raceIds = game.raceIds.filter((id) => id !== nextRace.id) as NonEmptyArray<string>
  }
}

const removeRaceEventFromRace = ({
  state,
  previousRaceEvent,
  nextRaceEvent
}: {
  state: GameState
  previousRaceEvent?: NormalizedRaceEvent
  nextRaceEvent: NormalizedRaceEvent
}): void => {
  if (!previousRaceEvent) {
    return
  }

  if (previousRaceEvent.uuid !== nextRaceEvent.uuid) {
    console.error('Should not happen, prev and next race event are different:', {
      previousRaceEvent,
      nextRaceEvent
    })
    return
  }

  const race = state.races.byId[previousRaceEvent.raceId]

  if (!race) {
    console.error('Should not happen, race not found for race event:', {
      previousRaceEvent,
      nextRaceEvent
    })
    return
  }

  if (previousRaceEvent.raceId !== nextRaceEvent.raceId) {
    // remove race result from old race
    race.raceEventIds = race.raceEventIds.filter((id) => id !== nextRaceEvent.uuid)
  }
}

const setRaces = (state: GameState, races: NonEmptyArray<Race>) => {
  races.forEach((race) => {
    // Add race to normalized list
    state.races.byId[race.uuid] = RaceMap.toNormalizedRace(race)

    // Add race id if it doesn't exist
    if (!state.races.allIds.includes(race.uuid)) {
      state.races.allIds.unshift(race.uuid)
    }
  })

  return state
}

const setGame = (state: GameState, game: MarbleGame): void => {
  // Set current game id
  // state.currentGameId = game.id // We can't do this otherwise can't reuse this function in our setGames state

  // Add game to normalized list
  state.games.byId[game.id] = GameMap.toNormalizedMarbleGame(game)

  // Add game id if it doesn't exist
  if (!state.games.allIds.includes(game.id)) {
    state.games.allIds.unshift(game.id)
  }

  setRaces(state, game.races)

  // Add track to normalized list
  state.tracks.byId[game.track.uuid] = TrackMap.toNormalizedTrack(game.track)

  // Add track id if it doesn't exist
  if (!state.tracks.allIds.includes(game.track.uuid)) {
    state.tracks.allIds.unshift(game.track.uuid)
  }

  state.trackModes.byId[game.track.mode.uuid] = TrackModeMap.toNormalizedTrackMode(
    game.track.mode,
    game.track.uuid
  )
  // Add track m id if it doesn't exist
  if (!state.trackModes.allIds.includes(game.track.mode.uuid)) {
    state.trackModes.allIds.unshift(game.track.mode.uuid)
  }

  game.marbles.map((marble) => {
    state.marbles.byId[marble.uuid] = MarbleMap.toNormalizedMarble(marble)

    // Add marble id if it doesn't exist
    if (!state.marbles.allIds.includes(marble.uuid)) {
      state.marbles.allIds.unshift(marble.uuid)
    }
  })

  game.races.forEach((race) => {
    race.raceEvents.forEach((raceEvent) => {
      state.raceEvents.byId[raceEvent.uuid] = RaceEventMap.toNormalizedRaceEvent(raceEvent)

      // Add race event id if it doesn't exist
      if (!state.raceEvents.allIds.includes(raceEvent.uuid)) {
        state.raceEvents.allIds.unshift(raceEvent.uuid)
      }
    })
  })
}

export const useGameStore = create<GameStore>()(
  devtools(
    immer((set) => ({
      currentGameId: undefined,
      currentLanguage: getQueryParam('language') || fallbackLng,
      config: {},
      games: {
        byId: {},
        allIds: []
      },
      races: {
        byId: {},
        allIds: []
      },
      raceEvents: {
        byId: {},
        allIds: []
      },
      tracks: {
        byId: {},
        allIds: []
      },
      trackModes: {
        byId: {},
        allIds: []
      },
      marbles: {
        byId: {},
        allIds: []
      },
      actions: {
        raceEventAdded: (raceEvent) => {
          set(
            (state) => {
              // console.info('[raceEventAdded] triggered:', raceEvent)
              addRaceEvent(raceEvent)(state)
            },
            undefined,
            { type: 'raceEventAdded', raceEvent } as any
          )
        },
        raceEventUpdated: (raceEvent) => {
          set(
            (state) => {
              const existingRaceEvent = original(state.raceEvents.byId[raceEvent.uuid])
              state.raceEvents.byId[raceEvent.uuid] = RaceEventMap.toNormalizedRaceEvent(raceEvent)

              removeRaceEventFromRace({
                state,
                previousRaceEvent: existingRaceEvent,
                nextRaceEvent: raceEvent
              })

              const race = state.races.byId[raceEvent.raceId]

              if (!race) {
                return state
              }

              if (!race.raceEventIds.includes(raceEvent.uuid)) {
                addRaceEventToExistingRace(state, raceEvent)
              }
            },
            undefined,
            { type: 'raceEventUpdated', raceEvent } as any
          )
        },
        gameUpdated: (partialGame) => {
          set(
            (state) => {
              const existingGame = state.games.byId[partialGame.id]

              if (!existingGame) {
                return state
              }

              state.games.byId[partialGame.id] = {
                ...existingGame,
                ...partialGame
              }
            },
            undefined,
            'gameUpdated'
          )
        },
        raceAdded: (race) => {
          set(
            (state) => {
              // Get race events
              const raceEventIds = pipe(
                Object.values(state.raceEvents.byId),
                A.filter((raceEvent) => raceEvent.raceId === race.id),
                A.sortBy(orderRaceEventsByTimestamp),
                A.map((raceEvent) => raceEvent.uuid)
              )

              const enrichedNormalizedRace = {
                ...race,
                raceEventIds
              }

              state.races.byId[race.id] = enrichedNormalizedRace
              state.races.allIds.unshift(race.id)
              addRaceToExistingGame(state, enrichedNormalizedRace)
            },
            undefined,
            { type: 'raceAdded', race } as any
          )
        },
        raceUpdated: (race) => {
          set(
            (state) => {
              const existingRace = original(state.races.byId[race.id])

              const updatedRace: NormalizedRace = existingRace
                ? { ...existingRace, ...race }
                : ({ ...race, raceEventIds: [] } as NormalizedRace)

              state.races.byId[race.id] = updatedRace

              if (!existingRace) {
                state.races.allIds.unshift(race.id)
              }

              removeRaceFromGame({
                state,
                previousRace: existingRace,
                nextRace: updatedRace
              })

              const game = race.raceConfigurationId && state.games.byId[race.raceConfigurationId]

              if (!game) {
                return state
              }

              // Add race id to existing game
              if (!game.raceIds.includes(race.id)) {
                addRaceToExistingGame(state, updatedRace)
              }
            },
            undefined,
            { type: 'raceUpdated', race } as any
          )
        },
        /// ----
        setRaces: (gameId, races) => {
          set(
            (state) => {
              setRaces(state, races)

              state.actions.updateGameById({ id: gameId, races })
            },
            undefined,
            { type: 'setRaces', gameId, races } as any
          )
        },
        setCurrentGame: (game) => {
          set(
            (state) => {
              // Set current game id to undefined if game is unknown
              if (isUnknownGame(game)) {
                state.currentGameId = undefined
                return
              }

              setGame(state, game)
              state.currentGameId = game.id
            },
            undefined,
            { type: 'setCurrentGame', game } as any
          )
        },
        setGames: (games) => {
          set(
            (state) => {
              const ids = Object.keys(games)

              ids.forEach((id) => {
                state.games.byId[id] = GameMap.toNormalizedMarbleGame(games[id]!)
              })

              state.games.allIds = ids

              // races...
              Object.values(games).forEach((game) => {
                setGame(state, game)
              })
            },
            undefined,
            { type: 'setGames', games } as any
          )
        },
        updateGameById: (newGameData: Partial<MarbleGame> & { id: string }) => {
          set(
            (state) => {
              const gameToUpdateNormalized = state.games.byId[newGameData.id]

              if (!gameToUpdateNormalized) {
                // console.info('Cannot update non-existent game')
                return state
              }

              const oldGame = getGameSelector(state, gameToUpdateNormalized.id)

              if (!isMarbleGame(oldGame)) {
                console.error('Should not happen: game is not a MarbleGame', oldGame)
                return state
              }

              const updatedGame: MarbleGame = {
                ...oldGame,
                ...newGameData
              }

              state.games.byId[newGameData.id] = GameMap.toNormalizedMarbleGame(updatedGame)
            },
            undefined,
            { type: 'updateGameById', newGameData } as any
          )
        },
        updateTrack: (trackDTO: RealtimeTrackDTO) => {
          set(
            (state) => {
              const trackMode = pipe(
                state.trackModes.allIds,
                A.map((id) => state.trackModes.byId[id]),
                A.map(O.fromNullable),
                A.getSomes,
                A.filter((mode) => mode.active),
                A.findFirst((mode) => mode.trackId === trackDTO.id)
              )

              if (O.isNone(trackMode)) {
                // console.info('No active track mode found for track', trackDTO.id)
                return state
              }

              const normalizedTrack: NormalizedTrack = {
                uuid: trackDTO.id,
                name: trackDTO.name,
                shortName: trackDTO.short_name,
                description: trackDTO.description,
                image: trackDTO.image,
                poster: trackDTO.poster,
                background: trackDTO.background,
                createdAt: trackDTO.created_at,
                active: trackDTO.active,
                showInLobby: trackDTO.show_in_lobby,
                modeId: trackMode.value.uuid
              }

              state.tracks.byId[trackDTO.id] = normalizedTrack
            },
            undefined,
            { type: 'updateTrack', trackDTO } as any
          )
        },
        updateTrackMode: (trackModeDTO: RealtimeTrackModeDTO) => {
          set(
            (state) => {
              const trackMode = TrackModeMap.fromTrackModeDTO(trackModeDTO)

              if (trackModeDTO.track_id) {
                state.trackModes.byId[trackModeDTO.id] = TrackModeMap.toNormalizedTrackMode(
                  trackMode,
                  trackModeDTO.track_id
                )
              }
            },
            undefined,
            { type: 'updateTrackMode', trackModeDTO } as any
          )
        },
        tokenGameConfigRetrieved: (tokenGameConfig: TokenGameConfigDTO) => {
          set(
            (state) => {
              state.config = {
                ...state.config,
                externalDepositUrl: tokenGameConfig.externalDepositUrl,
                externalLobbyUrl: tokenGameConfig.externalLobbyUrl,
                platform: tokenGameConfig.platform,
                language: tokenGameConfig.language,
                country: tokenGameConfig.country
              }
              if (tokenGameConfig.language) {
                state.currentLanguage = tokenGameConfig.language
              }
            },
            undefined,
            { type: 'tokenGameConfigRetrieved', tokenGameConfig } as any
          )
        },
        setCurrentLanguage: (language: string) => {
          set(
            (state) => {
              state.currentLanguage = language
            },
            undefined,
            { type: 'setCurrentLanguage', language } as any
          )
        },
        setGameConfig: (dto: GameConfigDTO) => {
          set(
            (state) => {
              const config = {
                ...state.config,
                bet: {
                  currency: dto.bet.currency,
                  limits: dto.bet.limits
                }
              }
              state.config = config
            },
            undefined,
            { type: 'setGameConfig', dto } as any
          )
        }
      }
    })),
    { name: 'GameStore', enabled: isDevtoolsEnabled() }
  )
)

export const useGameActions = () => useGameStore((state) => state.actions)

export const useGetGameConfig = () => useGameStore((state) => state.config)

export const useGetCurrentGameId = () => useGameStore((state) => state.currentGameId)

export const useGetGames = () => useGameStore((state) => state.games.byId)

export const useGetGameIds = () => useGameStore((state) => state.games.allIds)

export const useGetCurrentLanguage = () => useGameStore((state) => state.currentLanguage)

export const useGetGameBetConfig = (player: Player) => {
  return useGameStore((state) => {
    const gameConfig = state.config

    if (!isKnownGameConfig(gameConfig)) {
      return undefined
    }

    if (!isAuthenticatedPlayer(player)) {
      return undefined
    }

    if (gameConfig.bet.currency !== player.balance.currency) {
      return undefined
    }

    return gameConfig.bet
  })
}

export const useGetCurrentGame = (): Game => {
  return useGameStore((state) => {
    const currentGame = state.currentGameId && state.games.byId[state.currentGameId]

    if (!currentGame) {
      return unknownGame
    }

    return getGameSelector(state, currentGame.id)
  })
}

export const useGetGameById = (gameId?: string) =>
  useGameStore((state) => {
    if (!gameId) {
      return unknownGame
    }

    const normalizedGame = state.games.byId[gameId]

    if (!normalizedGame) {
      return unknownGame
    }

    return getGameSelector(state, normalizedGame.id)
  })

// This selector is used in different Zustand selectors, so it is not memoized
// By using reselect, we can memoize parts of logic
const getGameSelector = createSelector(
  [
    ({ currentGameId: _currentGameId, ...rest }: GameState) => rest,
    (_state: GameState, gameId: string) => gameId
  ],
  (state, gameId: string) => {
    const game = state.games.byId[gameId]

    if (!game || !game.active) {
      return unknownGame
    }

    const marbles = pipe(
      game.marbleIds,
      A.map((id) => state.marbles.byId[id]),
      A.map(O.fromNullable),
      A.getSomes,
      A.map(MarbleMap.fromNormalizedMarble)
    )

    const races = pipe(
      game.raceIds,
      A.map((id) => state.races.byId[id]),
      A.map(O.fromNullable),
      A.getSomes,
      A.map((normalizedRace) => {
        const raceEvents = pipe(
          normalizedRace.raceEventIds,
          A.map((id) => state.raceEvents.byId[id]),
          A.map(O.fromNullable),
          A.getSomes,
          A.sortBy(orderRaceEventsByTimestamp),
          A.map(RaceEventMap.fromNormalizedRaceEvent)
        )

        if (raceEvents.length === 0) {
          return null
        }

        const race: Race = {
          uuid: normalizedRace.id,
          round: normalizedRace.round,
          createdAt: normalizedRace.createdAt,
          raceConfigurationId: game.id,
          raceEvents
        }

        return race
      }),
      A.map(O.fromNullable),
      A.getSomes
    )

    if (!A.isNonEmptyArray(races)) {
      console.error('empty races')
      return unknownGame
    }

    const trackOpt = pipe(
      O.fromNullable(state.tracks.byId[game.trackId]),
      O.filter((track) => track.active),
      O.flatMap((normalizedTrack) =>
        pipe(
          O.fromNullable(state.trackModes.byId[normalizedTrack.modeId]),
          O.filter((trackMode) => trackMode.active),
          O.map(TrackModeMap.fromNormalizedTrackMode),
          O.map((trackMode) => TrackMap.fromNormalizedTrack(normalizedTrack, trackMode))
        )
      )
    )

    if (O.isNone(trackOpt)) {
      return unknownGame
    }

    const marbleGame: MarbleGame = {
      type: 'MarbleGame',
      id: game.id,
      name: game.name,
      laps: game.laps,
      totalMarbles: game.totalMarbles,
      maxPickableMarbles: game.maxPickableMarbles,
      active: game.active,
      createdAt: game.createdAt,
      previewThumbnail: game.previewThumbnail,
      marbles,
      races,
      track: trackOpt.value
    }

    return marbleGame
  }
)

const addRaceEvent = (raceEvent: RaceEvent) => (state: GameState) => {
  const normalizedRaceEvent = RaceEventMap.toNormalizedRaceEvent(raceEvent)
  state.raceEvents.byId[raceEvent.uuid] = normalizedRaceEvent
  state.raceEvents.allIds.unshift(raceEvent.uuid)

  const race = state.races.byId[raceEvent.raceId]

  if (!race) {
    return
  }

  const sortedRaceEventIds = pipe(
    race.raceEventIds,
    A.map((id) => state.raceEvents.byId[id]),
    A.append(normalizedRaceEvent),
    A.map(O.fromNullable),
    A.getSomes,
    A.sortBy(orderRaceEventsByTimestamp),
    A.map((re) => re.uuid)
  )

  state.races.byId[race.id] = {
    ...race,
    raceEventIds: sortedRaceEventIds
  }
}

export const __TEST__ = {
  getGameSelector
}
