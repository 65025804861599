import type { RealtimeTrackModeDTO, TrackModeDTO } from 'src/services/supabase/queries'
import type { NormalizedTrackMode } from 'src/store/types'
import type { TrackMode } from '../../domain/TrackMode'

export const fromTrackModeDTO = (trackMode: TrackModeDTO): TrackMode => {
  return {
    uuid: trackMode.id,
    name: trackMode.name,
    type: trackMode.type,
    active: trackMode.active
  }
}

export const fromRealtimeTrackModeDTO = (trackMode: RealtimeTrackModeDTO): TrackMode => {
  return fromTrackModeDTO(trackMode)
}

export const fromNormalizedTrackMode = (trackMode: NormalizedTrackMode): TrackMode => {
  return {
    uuid: trackMode.uuid,
    name: trackMode.name,
    type: trackMode.type,
    active: trackMode.active
  }
}

export const toNormalizedTrackMode = (
  trackMode: TrackMode,
  trackId: string
): NormalizedTrackMode => {
  return {
    uuid: trackMode.uuid,
    name: trackMode.name,
    type: trackMode.type,
    trackId,
    active: trackMode.active
  }
}
