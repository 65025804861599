import type { NonEmptyArray } from 'effect/Array'
import type { NormalizedMarbleGame } from 'src/store/types'
import type { Broadcast } from './Broadcast'
import type { Marble } from './Marble'
import type { Race } from './Race'
import type { Track } from './Track'

export interface UnknownGame {
  type: 'UnknownGame'
}

export interface MarbleGame {
  type: 'MarbleGame'
  marbles: Marble[]
  id: string
  name: string
  laps: number
  totalMarbles: number
  maxPickableMarbles: number
  active: boolean
  previewThumbnail?: string
  broadcast?: Broadcast[]
  track: Track
  races: NonEmptyArray<Race>
  createdAt: string
}

export type Game = UnknownGame | MarbleGame

export const unknownGame: UnknownGame = {
  type: 'UnknownGame'
}

export const isUnknownGame = (game: Game): game is UnknownGame => game.type === 'UnknownGame'

export const isNormalizedUnknownGame = (
  game: NormalizedMarbleGame | UnknownGame
): game is UnknownGame => game.type === 'UnknownGame'

export const isMarbleGame = (game: Game): game is MarbleGame => game.type === 'MarbleGame'

export const isNormalizedMarbleGame = (
  game: NormalizedMarbleGame | UnknownGame
): game is NormalizedMarbleGame => game.type === 'MarbleGame'

// export interface GameConfiguration {
//   marble: Marble[]
//   id: UUID
//   track_id: string
//   name: string
//   laps: number
//   total_marbles: number
//   max_pickable_marbles: number
//   active: boolean
//   preview_thumbnail: string
//   broadcast?: Broadcast[]
//   race?: Race[]
//   // race_configuration_betting_modes?: RaceConfigurationBettingModes[]
//   // marbles?: Marble[]
//   track?: Track
// }
